import React from "react";
import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  withStyles,
  Grid,
  Button,
  FormControl,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CloseIcon from "@material-ui/icons/Close";

import styles from "../styles";

class MeteringPointNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNotesOpen: false,
      noteText: "",
      expanded: false
    };
  }

  handleInput = event => {
    this.setState({ noteText: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ addNotesOpen: true });
  };

  handleClose = () => {
    this.setState({ addNotesOpen: false });
  };

  handlePanelExpansion = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  insertNotes = () => {
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: "post",
          url:
            thiz.props.context.config.meteringPointsAPI +
            "/" +
            thiz.props.meteringPointId +
            "/note",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token
          },
          data: [
            {
              user: thiz.props.context.keycloak.tokenParsed.name,
              text: thiz.state.noteText,
              timestamp: new Date()
            }
          ]
        };
        axios(config)
          .then(() => {
            thiz.props.updateMeteringPoint(thiz.props.meteringPointId);
            thiz.setState({ addNotesOpen: false, noteText: "" });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  render() {
    const { expanded } = this.state;
    const { classes, intl } = this.props;

    //Split notes list into two parts if longer than 3
    var notes;
    if (expanded) {
      notes = this.props.notes;
    } else {
      notes = this.props.notes.slice(0, 3);
    }
   
    return (
      <div>
        <Paper className={classes.Paper}>
          <div className={classes.AlignRight}>
            <IconButton onClick={this.props.handleToggleShowNotes}>
              <CloseIcon />
            </IconButton>
          </div>
          <Table size="small">
            {notes.length > 0 ? (
              <React.Fragment>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.Width75Percent}>
                      <FormattedMessage id="metering_points.note" />
                    </TableCell>
                    <TableCell className={classes.Width15Percent}>
                      <FormattedMessage id="metering_points.note.user" />
                    </TableCell>
                    <TableCell align="right" className={classes.Width10Percent}>
                      <FormattedMessage id="metering_points.note.timestamp" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {notes.map(note => (
                    note.text.includes("Changed status to") ? 
                    <TableRow key={note.user + note.timestamp} hover>
                      <TableCell className={classes.NoteTableCell}>
                        <FormattedMessage id="metering_points.changed_status" />{" "}
                        {(note.text.includes("['']") ?  
                            <FormattedMessage id={"metering_points.status_none"}/>
                         : 
                         "'" + intl.formatMessage({id:"metering_points.status_" + note.text.slice(20,-2)}) + "'")}
                      </TableCell>
                      <TableCell>{note.user}</TableCell>
                      <TableCell align="right">
                        {new Date(note.timestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                    : 
                    <TableRow key={note.user + note.timestamp} hover>
                      <TableCell className={classes.NoteTableCell}>
                         {note.text}
                      </TableCell>
                      <TableCell>{note.user}</TableCell>
                      <TableCell align="right">
                        {new Date(note.timestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </React.Fragment>
            ) : null}
          </Table>
          <Grid container>
            <Grid item xs={4}>
              <IconButton onClick={this.handleClickOpen}>
                <NoteAddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4} className={classes.AlignCenter}>
              <IconButton
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          open={this.state.addNotesOpen}
          onClose={this.handleClose}
          aria-labelledby="notes-dialog-title"
          aria-describedby="notes-dialog-description"
        >
          <DialogTitle id="notes-dialog-title">
            <FormattedMessage id="metering_points.write_note_here" />
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <Input
                multiline={true}
                id="new_note_input"
                onChange={this.handleInput}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={e => {
                this.handleClose(e);
              }}
              color="secondary"
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              onClick={e => {
                this.insertNotes(e);
                this.handleClose(e);
              }}
            >
              <FormattedMessage id="metering_points.add_note_button" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(MeteringPointNotes));
